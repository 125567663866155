import React from 'react';
import axios from 'axios';
import Helmet from 'react-helmet';
import ReactMarkdown from "react-markdown"

import '@styles/print.scss';

import Logo from '@components/header/assets/img/logo.svg';
import SEO from "../components/seo"

class PropertyDetailsPrint extends React.Component {

  // State will apply to the posts object which is set to loading by default
  state = {
    posts: [],
    isLoading: true,
    errors: null
  };


  // Now we're going to make a request for data using axios
  getPosts(id) {
    axios
      // This is where the data is hosted
      // TODO below url need to update in env file
      .get('https://holroyds-strapi.q.starberry.com/properties/'+id)
      // Once we get a response and store data, let's change the loading state
      .then(response => {
        this.setState({
          posts: response.data,
          isLoading: false
        });
      })
      // If we catch any errors connecting, let's update accordingly
      .catch(error => this.setState({ error, isLoading: false }));
  }
  // Let's our app know we're ready to render the data
  componentDidMount() {
  const url = typeof window !== 'undefined' ? window.location.href : '';

  let property_slug = url.split('-');
  let id = property_slug[property_slug.length-1];
    this.getPosts(id);
  }
  // Putting that data to use
  render() {
    const { isLoading, posts } = this.state;


	const Header = () => {
	return <>
	<SEO title={posts.display_address} description={posts.display_address} />
			<div class="common-header">
				<div class="left-part">
					<a href="" title="Holroyds Estate Agents" target="_blank"><Logo /></a>
				</div>
				<div class="right-part">
					<div class="phone-no item-phone">Call us on <b>01535 610021</b></div>
				</div>
				<div class="clr"></div>
				</div>
			<div class="clr"></div>
	</>
	}

	const year = new Date().getFullYear();

	const Footer = () => {
	return <>
			<div class="footer">
				<div class="top-part">
					<span class="address">Office Address: </span>59 North Street, Keighley, BD21 3SL
				</div>
				<div class="bottom-part">
					<div class="left-part">© {year} Holroyds Estate Agents. All Rights Reserved.</div>
					<div class="right-part"><a href="https://www.holroydsestateagents.co.uk/" target="_blank">www.holroydsestateagents.co.uk</a></div>
				</div>
				<div class="clr"></div>
			</div>    
	</>
	}

	const img = String(posts.images)

	  var json = [];

	  var toSplit = img.split(",");
	  for (var i = 0; i < toSplit.length; i++) {
	      json.push({"images":toSplit[i]});
	  }


    return (
      <>
        {!isLoading ? (
          <>
			<div class="printWrapper">
	    		<div class="contentWrapper">
					<Header/>


			    	<div class="contentarea page-1">
					    <div class="heading-txt">
					            <div class="prop-price"><span class="price-qualifier" data-price={posts.price}>£{posts.price.toLocaleString()}</span></div>
					            <div class="prop-address">
					                {posts.display_address}
					            </div>
					    </div>                            
    					<div class="photo">
				          {json.slice(0,1).map(({images}, key) => {
				            return (
				            <>
                    		<img src={images} />
                    		</>
				            )
				          })}
                		</div>
                        <div class="full-details">
                			<div class="description">
                    			<ul class="rooms">
									<li class="Bedrooms">{posts.bedrooms} Bedrooms / {posts.bathrooms} Bathroooms</li>
								</ul>
	                    		<div class="clr"></div>
	                    		<p><ReactMarkdown source={posts.description} /></p>
                			</div>
            			</div>
            			<div class="clr"></div>
            
					    <Footer />      
					</div>

					<div class="pagebreak"></div>

					{json.length > 1 &&
					<>
					<Header/>
					<div class="contentarea page-2">
        
					    <div class="heading-txt">
					            <div class="prop-price"><span class="price-qualifier" data-price={posts.price}>£{posts.price.toLocaleString()}</span></div>
					            <div class="prop-address">
					                {posts.display_address}
					            </div>
					    </div>           
    					<div class="photos">

				          {json.slice(1,7).map(({images}, key) => {
				          	var align = key % 2
				          	if(align == 0)
				          	{
				          		var css = "slide"
				          	}
				          	else
				          	{
				          		var css = "slide last"
				          	}
				            return (
				            <>
	                    		<div class={css}>
					                <img src={images} />
					            </div>
                    		</>
				            )
				          })}

						</div>
            			<div class="clr"></div>
            			<Footer />  
    				</div>

					<div class="pagebreak"></div>
					</>
					}

					{posts.floorplan != null && posts.floorplan != "" &&
					<>
					<Header/>

					<div class="contentarea page-3">
            
					    <div class="heading-txt">
					            <div class="prop-price"><span class="price-qualifier" data-price={posts.price}>£{posts.price.toLocaleString()}</span></div>
					            <div class="prop-address">
					                {posts.display_address}
					            </div>
					    </div>            
    					<div class="floorplan">
							<img src={posts.floorplan} />
						</div>
            			<div class="clr"></div>
            
					    <Footer />      
					</div>

					<div class="pagebreak"></div>
					</>
					}

					{/*<Header/>

					<div class="contentarea page-4">
                                    
					    <div class="heading-txt">
							<div class="prop-price"><span class="price-qualifier" data-price={posts.price}>£{posts.price.toLocaleString()}</span></div>
							<div class="prop-address">
						    {posts.display_address}
							</div>
						</div>                  
	    				<div class="map">
	                        <img src={`https://maps.googleapis.com/maps/api/staticmap?size=690x405&amp;maptype=roadmap&amp;markers=icon:https://holroyds-dev.q.starberry.com/images/marker.png|${posts.latitude},${posts.longitude}&amp;sensor=false&amp;scale=2&amp;zoom=15&amp;key=AIzaSyB0l8nH7omj_oshEF46CGOi1rPQk2ZP_IA`} id="map-static" width="690" height="405" />
	                    </div>
						<div class="clr"></div>
	            		<Footer />  
    				</div>*/}



				</div>
			</div>




          </>
        ) : (
          <div className="property-loading-screen">
            <Logo />
              <p>Loading...</p>
          </div>
          
        )}
      </>
    );
  }
}
export default PropertyDetailsPrint;